.logo {
  width: 200, height 80;
}

.newButtons {
  border: "1px solid #6F23FF";
  border-radius: "50px";
}

.menuName {
  color: #6F23FF;
  padding: 16px;
  font-weight: 800;
  line-height: 50px;
}

.addButton {
  height: 60px;
  cursor: pointer;
}

.previewParticipantContainer {
  height: 32px;
  display: flex;
  flex-direction: row;
}

.moreParticipants {
  font-size: 14px;
  color: #919397;
  text-align: center;
  padding-top: 6px;
  width: 56px;
  height: 56px;
}

@media screen and (max-width: 900px) {
  .hidden {
    display: none !important;
  }
}
@media screen and (min-width: 900px) {
  .mobile {
    display: none !important;
  }
}

@media screen and (min-width: 900px) {
  .mobileShow {
    display: none !important;
  }
}

.mobile {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
