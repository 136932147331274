.title {
  font-weight: bold;
  font-size: 20px;
  margin: 0;
  flex-grow: 0;
}

@media screen and (min-width: 900px) {
  .mobile {
    display: none !important;
  }
}

.midTitles {
  background: #ffffff;
  margin-top: 20px;
  width: "100%";
  overflow: auto;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 10px;
}

@media screen and (min-width: 768px) {
  .midTitles {
    max-height: 500px;
  }
}

.topBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: #151d28;
  background: -webkit-linear-gradient(
    to right,
    #413096,
    #172263,
    #431763
  );
  background: linear-gradient(
    to right,
    #413096,
    #172263,
    #431763
  );  */

  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  /* color: white; */
  margin-bottom: 20px;
}