.hidden {
  display: none !important;
}

.boxesHover {
  display: flex;
  width: 100%;
  height: 110px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 10px;
  padding: 20px;
  transition: ease-in-out 0.2s;
}

.boxesHover:hover {
  box-shadow: 0px 3px 10px 3px rgba(23, 7, 77, 0.3);
  transition: ease-in-out 0.2s;
}

.boxes {
  display: flex;
  width: 100%;
  height: 110px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 10px !important;
  padding: 20px;
  transition: ease-in-out 0.2s;
}

.smallBoxes {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  transition: ease-in-out 0.2s;
}
