.container {
    /* height: 30px; */
    display: flex;
}

.container:hover {
    cursor: pointer;
}

.profile {
    width: 30px;
    height: 30px;
    border-radius: 20px;
}

.dropDownContainer {
    width: 10px;
    height: 10px;
    margin: 10px 10px;
}

.name {
    width: 30px;
    height: 30px;
    text-align: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.2);
    font-size: 20px;
    line-height: 30px;
    font-weight: bolder;
    text-transform: uppercase;
    z-index: 10;
}